<template>
  <div id="app" @scroll="scrollApp($event)">
    <div class="bar bar-top">
      <div class="bar-left"></div>
      <div class="bar-right"></div>
    </div>
    <div class="main-content">
      <div class="nav" :style="{ top }" ref="navs">
        <div class="logo" @click="$router.push('/')">
          <img class="logo-img" src="/images/logo.png" />
        </div>
        <ul class="nav-ul par">
          <li class="nav-li" v-for="(nav, i) in navList" :key="i">
            <div
              class="nav-text"
              :class="{ active: nav.path === $route.path }"
              @click="chooseNav(nav)"
            >
              {{ nav.title }}
              <div class="name" v-if="i === 0">
                <template v-for="(str, i) in nameList">
                  <span :key="i">&nbsp;</span>
                  <template v-for="(d, j) in str">
                    <span
                      :key="`${i}_${j}`"
                      :style="{ color: colorList[j % 3] }"
                      >{{ d }}</span
                    >
                  </template>
                </template>
              </div>
            </div>
          </li>
        </ul>
        <div class="line"></div>
        <ul class="nav-ul">
          <li class="nav-li" v-for="(pro, i) in projectList" :key="i">
            <div
              class="nav-text pro"
              :class="{ active: $route.path === `/project/${pro.id}` }"
              @click.self="pathToProject(pro.id)"
            >
              {{ pro.name }}
            </div>
          </li>
        </ul>
        <div class="contact">
          <div class="left">
            <div class="out-chain-box">
              <a
                target="_blank"
                class="out-chain-item"
                href="https://www.linkedin.com/company/cubic-space"
              >
                <img src="/images/linkedin.png" alt="" />
              </a>
              <a
                target="_blank"
                class="out-chain-item"
                href="https://twitter.com/CubicSpaceArt"
              >
                <img src="/images/twitter.png" alt="" />
              </a>
            </div>
            <div>Contact Us:</div>
            <div>Email: info@sklf.com.cn</div>
            <div>
              Address: 4th floor, F13 block, F518 Creative Park, Baoyuan Road,
              Baoan District, Shenzhen, China, 518102
            </div>
          </div>
          <div class="right">
            <img class="contact-img" src="/images/logo1.png" alt="" />
            <div>@2023 Cubic Space</div>
          </div>
        </div>
      </div>

      <div class="content">
        <router-view />
      </div>
    </div>
    <div class="bar bar-btm" v-if="showBtm">
      <div class="bar-left"></div>
      <div class="bar-right"></div>
    </div>
    <div class="nav-mobile" v-if="isMobile">
      <div class="logo" @click="$router.push('/')">
        <img class="logo-img" src="/images/logo.png" />
      </div>
      <div class="menu" @click="toggleMenu">
        <div class="menu-line"></div>
        <div class="menu-line"></div>
        <div class="menu-line"></div>
      </div>
    </div>
    <div class="mobile-menu" v-show="isMobile && showMobileMenu">
      <ul class="nav-ul">
        <li class="nav-li" v-for="(nav, i) in navList" :key="i">
          <div
            class="nav-text"
            :class="{ active: nav.path === $route.path }"
            @click="chooseNav(nav)"
          >
            {{ nav.title }}
            <div class="name" v-if="i === 0">
              <template v-for="(str, i) in nameList">
                <span :key="i">&nbsp;</span>
                <template v-for="(d, j) in str">
                  <span
                    :key="`${i}_${j}`"
                    :style="{ color: colorList[j % 3] }"
                    >{{ d }}</span
                  >
                </template>
              </template>
            </div>
          </div>
        </li>
      </ul>

      <ul class="nav-ul">
        <li class="nav-li" v-for="(pro, i) in projectList" :key="i">
          <div
            class="nav-text pro"
            :class="{ active: $route.path === `/project/${pro.id}` }"
            @click.self="pathToProject(pro.id)"
          >
            {{ pro.name }}
          </div>
        </li>
      </ul>

      <div class="contact">
        <div class="left">
          <div class="out-chain-box">
            <a
              target="_blank"
              class="out-chain-item"
              href="https://www.linkedin.com/company/cubic-space"
            >
              <img src="/images/linkedin.png" alt="" />
            </a>
            <a
              target="_blank"
              class="out-chain-item"
              href="https://twitter.com/CubicSpaceArt"
            >
              <img src="/images/twitter.png" alt="" />
            </a>
          </div>
          <div>Contact Us:</div>
          <div>Email: info@sklf.com.cn</div>
          <div>
            Address: 4th floor, F13 block, F518 Creative Park, Baoyuan Road,
            Baoan District, Shenzhen, China, 518102
          </div>
        </div>
        <div class="right">
          <img class="contact-img" src="/images/logo1.png" alt="" />
          <div>@2023 Cubic Space</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters(['projectList', 'isMobile']),
    ...mapMutations(['changeMedia']),
    showTop() {
      const { path } = this.$route
      return (
        path.startsWith('/art') || path === '/' || path.startsWith('/about')
      )
    },
    showBtm() {
      const { path } = this.$route
      const hide =
        path === '/' || path.startsWith('/project') || path.startsWith('/about')
      return !hide
    }
  },
  data() {
    return {
      top: 0,
      nameList: ['CUBIC', 'SPACE'],
      colorList: ['#0044b6', '#1ca9ff', '#67dfdf'],
      choosedIndex: '',
      showMobileMenu: true,
      navList: [
        { title: 'About', path: '/about' },
        { title: '2D Art', path: '/art/2d' },
        { title: '3D Art', path: '/art/3d' },
        { title: 'VFX', path: '/art/vfx' },
        { title: 'Animation', path: '/art/ani' },
        { title: 'Motion Capture Studio', path: '/art/motion' },
        { title: 'Game Development', path: '/art/game' }
      ]
    }
  },
  mounted() {
    this.checkMedia()
    document.addEventListener('scroll', this.scroll)
    document.addEventListener('resize', this.checkMedia)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scroll)
  },
  methods: {
    // 点击菜单
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    checkMedia() {
      const w = document.body.offsetWidth
      this.$store.commit('changeMedia', w <= 768)
    },
    scroll() {
      if (this.isMobile) return
      const documentHeight = document.documentElement.clientHeight
      const navHeight = this.$refs.navs.offsetHeight
      const scrollTop = document.documentElement.scrollTop

      if (scrollTop + documentHeight >= navHeight) {
        this.top = documentHeight + scrollTop - navHeight - 30 + 'px'
      } else {
        this.top = 0
      }
    },
    chooseNav(nav) {
      this.showMobileMenu = false
      this.$router.push(nav.path)
    },
    pathToProject(id) {
      if (this.$route.path === `/project/${id}`) return
      this.showMobileMenu = false
      this.$router.push(`/project/${id}`)
    }
  }
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
}
html,
body,
div,
p,
li,
ul,
span,
i,
nav aside,
footer {
  margin: 0;
  padding: 0;
}
ul,
li,
ol,
dl,
dt,
dd {
  list-style: none;
}

.content {
  background-size: cover;
  font-family: 'YuGo';
  font-weight: normal;
  margin-left: $nav-width;
}

.about-infobox,
.content,
.main-content {
  &::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: 'YuGo';
  src: url('./assets/fonts/YuGothic-Bold-01.ttf');
}

body {
  background: url('./assets/images/main_bg.png') repeat-y center;
  background-size: cover;
  font-family: 'YuGo';
}

#app {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}
</style>

<style lang="scss" scoped>
.logo {
  cursor: pointer;
  margin: 30px 19px 107px 38px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 26px;
  &-img {
    width: 100%;
    display: block;
  }
}

.nav {
  position: absolute;
  // font-family: 'YuGo';
  font-weight: normal;
  transition: top 0.4s ease-in-out;
  width: $nav-width;
  background: url('./assets/images/nav_bg.png') no-repeat center;
  background-size: cover;
  &-text {
    color: $base-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    .name {
      font-size: 33px;
    }
    &.pro {
      font-size: 26px;
      line-height: 33px;
    }
    &.active {
      color: $color-nav-active;
    }
    &:hover {
      color: $color-nav-hover;
    }
  }
  &-li {
    color: #8d8c8c;
    font-size: size(17px);
    line-height: 43.5px;
    // padding: 0 size(10px);
    padding-left: $nav-left-margin;
    position: relative;
    > .nav-ul {
      position: absolute;
      z-index: 4;
      left: 100%;
      padding: 10px 15px;
      background: #000;
      display: none;
      top: -50%;
    }
    &:hover {
      > .nav-ul {
        display: unset;
      }
    }
  }
}
.line {
  width: 42%;
  margin: 24px 0 20px $nav-left-margin;
  height: 2px;
  background: $base-color;
}
.bar {
  height: $bar-height;
  display: flex;
  align-items: center;
  &-left,
  &-right {
    height: 100%;
  }

  &-left {
    width: $nav-width;
    background: $bar-left-color;
    flex-shrink: 0;
  }
  &-right {
    flex: 1;
    left: $nav-width;
    background: $bar-right-color;
  }
}

.main-content {
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.contact {
  color: $base-color;
  font-size: 10px;
  display: flex;
  align-items: center;
  margin: 174px 0 0 43px;
  line-height: 12px;
  align-items: flex-end;
  padding-bottom: 10px;
  .left {
    flex: 1;
  }
  .right {
    text-align: center;
    margin-left: 3px;
    img {
      width: 46px;
      height: 46px;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    width: 100px;
    flex-shrink: 0;
  }
}

.out {
  &-chain {
    &-box {
      display: flex;
      align-items: center;
      width: 120px;
      margin-bottom: 12px;
      justify-content: flex-start;
    }
    &-item {
      margin-right: 10px;
      > img {
        display: block;
        width: 35px;
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  $nav-width: 300px;
  .nav {
    width: $nav-width;
    &-li {
      font-size: 16px;
      line-height: 24px;
    }

    &-text {
      &.pro {
        font-size: 14px;
        line-height: 20px;
      }
      .name {
        font-size: 16px;
      }
    }
  }
  .content {
    margin-left: $nav-width;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  $nav-width: 220px;
  .nav {
    width: $nav-width;
    &-li {
      font-size: 16px;
      line-height: 24px;
    }
    &-text {
      &.pro {
        font-size: 14px;
        line-height: 20px;
      }
      .name {
        font-size: 16px;
      }
    }
  }
  .content {
    margin-left: $nav-width;
  }
  .right {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  $nav-width: 220px;
  $base-font: 13px;
  $height: 45px;
  .contact {
    margin: 20px;
    font-size: 14px;
    flex-direction: column;
    line-height: 16px;
    .right {
      display: none;
    }
  }
  .nav {
    @include hidden;
    &-li {
      font-size: 18px;
      line-height: 32px;
      padding-left: 0;
    }
    &-text {
      text-align: center;
      justify-content: center;
      &.pro {
        font-size: 18px;
        line-height: 32px;
      }
      .name {
        font-size: 18px;
      }
    }
  }
  .nav-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $height;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    background: url('./assets/images/main_bg.png') no-repeat center;
    z-index: 1;
  }
  .mobile-menu {
    position: fixed;
    padding-top: 30px;
    top: $height;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: url('./assets/images/main_bg.png') no-repeat center;
    overflow: auto;
    &-item {
      font-size: 18px;
      text-align: center;
      color: $base-color;
    }
  }
  .menu {
    width: 30px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &-line {
      width: 100%;
      height: 3px;
      background: #fff;
    }
  }
  .content {
    margin-left: 0;
    padding-top: 45px;
  }
  .bar {
    display: none;
  }
  .logo {
    margin: unset;
    &-img {
      height: 26px;
      width: unset;
    }
  }
}
</style>
