import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueYouTube from 'vue-youtube'
import {WOW} from 'wowjs'
import 'wowjs/css/libs/animate.css'

Vue.config.productionTip = false
Vue.use(VueYouTube)

Vue.prototype.$wow = new WOW({
  live: false
})

Vue.prototype.initWOW = () => {
  const wow = new WOW({live: false})
  wow.init()
  wow.sync()
}
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
