import Vue from 'vue'
import Vuex from 'vuex'
import { projectIds, projectImgs } from '../utils'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
    showLargeImage: false,
    artImgList: [
      { img: projectImgs.art_apex_1, id: projectIds.apex },
      { img: projectImgs.art_league_1, id: projectIds.league_of_legends },
      { img: projectImgs.art_honor_1, id: projectIds.honor_kings },
      { img: projectImgs.art_tom_1, id: projectIds.tom_clancy },
      { img: projectImgs.art_call_duty_1, id: projectIds.call_of_duty_m },
      { img: projectImgs.art_gen_1, id: projectIds.art_gen_1 }
    ],
    homeProjectList: [
      { img: projectImgs.league, id: projectIds.league },
      // {img: projectImgs.harry, id: projectIds.harry_potter},
      { img: projectImgs.apex, id: projectIds.apex },
      { img: projectImgs.diablo, id: projectIds.diablo_immortal_m },
      { img: projectImgs.duty_1, id: projectIds.call_of_duty_m },
      { img: projectImgs.division, id: projectIds.division },
      { img: projectImgs.duty_2, id: projectIds.call_of_duty_m },
      { img: projectImgs.genshin, id: projectIds.honor_kings },
      { img: projectImgs.teamfight },
      { img: projectImgs.honor_kings_1, id: projectIds.honor_kings }
    ],
    dataList: [
      {
        id: projectIds.league_of_legends,
        name: 'League of Legends: Wild Rift',
        desc: [
          'Cubic Space is providing character concept, character Modeling, animation and VFX support for League of Legends: Wild Rift.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        poster: '',
        videoIds: '6QoYsKCwa-o',
        imgList: []
      },
      {
        id: projectIds.harry_potter,
        name: 'Harry Potter: Magic Awakened',
        desc: [
          'Cubic Space is providing character modeling, environment modeling, animation and VFX support for Harry Potter: Magic Awakened.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        poster: '',
        videoIds: 'sGcs3tx2VeQ',
        imgList: []
      },
      {
        id: projectIds.apex,
        name: 'Apex Legends Mobile',
        desc: [
          'Cubic Space is providing character modeling, environment modeling and animation support for Apex Legends Mobile.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: '6QoYsKCwa-o', // https://www.youtube.com/watch?v=
        poster: '',
        imgList: []
      },
      {
        id: projectIds.diablo_immortal_m,
        name: 'Diablo Immortal M',
        desc: [
          'Cubic Space is providing character concept, character modeling, environment modeling, animation and VFX support for Diablo Immortal M.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: 'mfm0VHQJZxE', // 2T7x1bObMYI
        poster: '',
        imgList: []
      },
      {
        id: projectIds.call_of_duty_m,
        name: 'Call of Duty M',
        desc: [
          'Cubic Space is providing animation and motion capture support for Call of Duty M.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: 'NpnAxWEAtmc',
        poster: '',
        imgList: []
      },
      {
        id: projectIds.tom_clancy,
        name: "Tom Clancy's The Division 2",
        desc: [
          "Cubic Space is providing animation support for Tom Clancy's The Division 2.",
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        poster: '',
        videoIds: '7-4cf-SJzqQ',
        imgList: []
      },
      {
        id: projectIds.genshin,
        name: 'Genshin Impact',
        desc: [
          'Cubic Space is providing animation support for Genshin Impact.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: '1Ip43DfbkUw',
        poster: '',
        imgList: []
      },
      {
        id: projectIds.honor_kings,
        name: 'Honor of Kings',
        desc: [
          'Cubic Space is providing character modeling, animation and VFX support for Honor of Kings.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: '6Yh6VODcRys',
        poster: '',
        imgList: []
      }
    ]
  },
  getters: {
    projectList: (state) => state.dataList,
    homeProjectList: (state) => state.homeProjectList,
    artImgList: (state) => state.artImgList,
    showLargeImage: (state) => state.showLargeImage,
    isMobile: state => state.isMobile
  },
  mutations: {
    /**
     * 是否展示大图
     * @param {*} state 
     * @param {*} data 
     */
    changeLargeImage(state, data) {
      state.showLargeImage = data
    },
    /**
     * 更新媒体类型
     * @param {*} state 
     * @param {*} isMobile 
     */
    changeMedia (state, isMobile) {
      state.isMobile = isMobile
    }
  },
  actions: {},
  modules: {}
})
