<template>
  <div class="about-box">
    <div class="about-infobox">
      <div class="about-infobox-item">
        <div
          class="about-item about-imgbox aboutimg wow bounceInLeft"
          data-wow-delay="0.5s"
        >
          <img class="about-img" src="/images/about_credits.png" alt="" />
        </div>
        <div class="about-item about-text text1 wow bounceInRight">
          <h2 class="about-item-title">
            <span class="nn">About</span> <span class="nn active">CUBIC</span>
            <span class="nn hover">SPACE</span>
          </h2>
          <div class="about-section-ox">
            <div class="about-section">
              Cubic Space is a leading art and animation studio based in
              Shenzhen China and is behind many global hits developed by top
              Chinese game companies.
            </div>
            <div class="about-section">
              Founded in 2012, Cubic Space has been a top-3 vendor for Tencent
              and Netease on their global projects for more than a decade.
            </div>
            <div class="about-section">
              With currently 654 full time employees in art and the largest
              animation and VFX team in China with solid AAA experiences, we
              provide full art pipeline including Concept Art, 3D Art,
              Animation, VFX and Motion Capture production services.
            </div>
          </div>
        </div>
      </div>

      <div class="about-infobox-item">
        <div
          class="about-item about-text text2 about-clients about-client wow bounceInLeft"
        >
          <h2 class="about-item-title">Clients:</h2>
          <div>
            <p
              class=""
              :data-wow-delay="`${getTime(i * 0.05)}s`"
              v-for="(item, i) in clientsList"
              :key="i"
            >
              <i>{{ item }}</i>
            </p>
          </div>
        </div>
        <div
          class="about-item about-imgbox clientsimg wow bounceInRight"
          data-wow-delay="0.5s"
        >
          <img class="about-img" src="/images/about_about.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  data() {
    return {
      clientsList: [
        'Tencent',
        'Netease',
        'miHoYo',
        'ByteDance',
        'Lilith Games',
        'FunPlus',
        'Outfit7',
        'Jam City',
        'Lingxi Games',
        'IGG',
        'Yotta Games',
        '37Games',
        'Topwar',
        'Moonton',
        'Magic Tavern',
        'Happy Elements',
        'Century Games',
        'Perfect World',
        'Tap4Fun',
        'ELEX',
        'YOOZO'
      ]
    }
  },
  methods: {
    getTime(num) {
      return Number(num.toFixed(5))
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initWOW()
    })
  }
}
</script>

<style lang="scss" scoped>
.about {
  &-box {
    background: url('../assets/images/main_bg.png') no-repeat center;
    background-size: cover;
  }
  &-infobox {
    &-item {
      display: flex;
      // align-items: center;
      flex-wrap: wrap;
      // padding: 35px 25px;
      line-height: 42px;
    }
  }
  &-clients {
    text-align: center;
  }
  &-section {
    // &:not(:last-child) {
      margin-bottom: 68px;
    // }
  }
  &-item {
    // width: 50%;
    font-size: 27px;
    color: $base-color;
    &-title {
      color: $color-nav-hover;
      margin-bottom: 36px;
      font-weight: 600;
      .nn {
        margin-left: 7px;
        color: $base-color;
        &.active {
          color: $color-nav-active;
        }
        &.hover {
          color: $color-nav-hover;
        }
      }
    }
    h2 {
      font-size: 34px;
      font-weight: 400;
      line-height: 48px;
    }
    &-long {
      width: 100%;
      text-align: center;
      padding: 30px;
    }
  }
  &-imgbox {
    &.aboutimg {
      width: 675px;
    }
    &.clientsimg {
      width: 776px;
    }
  }
  &-img {
    display: block;
    width: 100%;
  }
  &-text {
    margin: 170px 138px 20px 106px;
    box-sizing: border-box;
    line-height: 34px;
    flex: 1;
    &.text2 {
      margin: 28px 100px 20px 78px;
      line-height: 48px;
      .about-item-title {
        margin-bottom: 6px;
      }
    }
  }
  &-client {
    font-size: 20px;
    // font-style: italic;
    // h2 {
    //   color: $color-nav-active;
    //   font-size: 36px;
    // }
  }
}
.align-right {
  align-items: flex-end;
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .about {
    &-item {
      line-height: 32px;
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-client {
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-infobox-item {
      line-height: 28px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .about {
    &-item {
      line-height: 32px;
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-client {
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-infobox-item {
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    &-item {
      h2 {
        line-height: 23px;
      }
      &-title {
        margin-bottom:10px;
      }
      width: unset;
      font-size: $font-size-mobile-base;
      h2 {
        font-size: $font-size-mobile-title;
      }
    }
    &-text {
      margin: 0 10px;
      &.text2 {
        margin: unset;
        line-height: 23px;
      }
    }
    &-imgbox {
      width: 100% !important;
    }
    &-section {
      line-height: 1.5;
      margin-bottom: $font-size-mobile-base;
    }
    &-infobox {
      &-item {
        flex-direction: column;
        &:last-child {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
</style>
