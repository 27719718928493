/** project Id */
export const projectIds = {
  league_of_legends: 1,
  harry_potter: 2,
  apex: 3,
  diablo_immortal_m: 4,
  call_of_duty_m: 5,
  tom_clancy: 6,
  genshin: 7,
  honor_kings: 8
}
/** imgList */
export const projectImgs = {
  league: '/images/league.jpg',
  harry: '/images/harry.jpg',
  duty_1: '/images/duty_1.jpg',
  duty_2: '/images/duty_2.jpg',
  apex: '/images/apex.jpg',
  diablo: '/images/diablo.jpg',
  genshin: '/images/genshin.jpg',
  division: '/images/division.jpg',
  honor_kings_1: '/images/honor_1.jpg',
  teamfight: '/images/teamfight.jpg',
  art_apex_1: '/images/art_apex_1.jpg',
  art_call_duty_1: '/images/art_call_duty_1.jpg',
  art_gen_1: '/images/art_gen_1.webp',
  art_knives_1: '/images/art_knives_1.jpg',
  art_honor_1: '/images/art_honor_1.jpg',
  art_league_1: '/images/art_league_1.jpg',
  art_tom_1: '/images/art_tom_1.jpg',
  art_diablo_1: '/images/art_diablo_1.jpg',
  art_team_1: '/images/art_team_1.webp',
  art_dragon_1: '/images/art_dragon_1.jpg'
}
