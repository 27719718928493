<template>
  <div class="art-detail-box">
    <div
      class="art-detail-item wow fadeIn"
      data-wow-duration="2s"
      :data-wow-delay="`${i * 0.2}s`"
      v-for="(pro, i) in imgList"
      :key="i"
      @click="pathToProject(pro.id)"
    >
      <img class="art-detail-img" :src="pro.img" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
mapGetters
export default {
  computed: {
    ...mapGetters(['artImgList'])
  },
  props: {
    imgList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initWOW()
    })
  },
  methods: {
    pathToProject(id) {
      if (id === undefined) return
      this.$router.push(`/project/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: $color-nav-hover;
$border-width: 2px;
.art {
  &-detail {
    &-box {
      display: flex;
      flex-wrap: wrap;
      margin: 389px 123px 0 138px;
      position: relative;
      border-left: $border-width solid $border-color;
      border-top: $border-width solid $border-color;
    }
    &-item {
      width: calc(100% / 3);
      box-sizing: border-box;
      overflow: hidden;
      border-bottom: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
      &:hover {
        .art-detail-img {
          transform: scale(1.1);
        }
      }
    }
    &-img {
      transition: transform 0.3s;
      display: block;
      width: 100%;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .art {
    &-detail {
      &-box {
        margin: 84px 50px 0;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .art {
    &-detail {
      &-box {
        margin: 84px 50px 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  $mg20: 20px;
  .art {
    &-detail {
      &-item {
        width: 50%;
      }
      &-box {
        margin: 64px $mg20 0;
      }
    }
  }
}
</style>
