<template>
  <div class="project-box" v-if="currProject">
    <div class="project-info">
      <div class="project-info-title wow bounceInLeft">
        {{ currProject.name }}
      </div>
      <div
        class="project-info-desc wow bounceInLeft"
        v-for="(info, i) in currProject.desc"
        :key="i"
        :data-wow-delay="`${(i + 1) * 0.2}s`"
      >
        {{ info }}
      </div>
    </div>
    <div class="project-loop">
      <div class="video" ref="video" v-if="currProject.type === 'video'">
        <youtube
          ref="player"
          :style="{ width: '100%' }"
          :video-id="currProject.videoIds"
          @ready="onLoadReady"
        ></youtube>
      </div>
      <div
        v-else
        class="project-imgbox"
        @click="clickIcon"
        @mousemove="mousemove"
        @mouseout="local = ''"
        ref="imgbox"
      >
        <div
          class="icon"
          @click="clickIcon"
          :style="{ left, top }"
          :class="local"
          v-if="local !== ''"
        ></div>
        <div class="imgMask"></div>
        <div
          class="project-imgbox-content"
          :style="{
            width: contentWidth,
            transform: `translateX(-${moveLeft})`
          }"
        >
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/2d_1.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/2d_2.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/2d_3.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/2d_4.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/3d_1.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/3d_2.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/3d_3.png"
          />
          <img
            class="project-img"
            :style="{ width: imgWidth }"
            src="/images/3d_4.png"
          />
        </div>
      </div>
    </div>
    <div class="project-page">
      <div
        class="page-prev page-item"
        :class="{ show: prevProject }"
        @click="goToProject(-1)"
      >
        {{ prevProject.name }}
      </div>
      <div
        class="page-next page-item"
        :class="{ show: nextProject }"
        @click="goToProject(1)"
      >
        {{ nextProject.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['showLargeImage', 'projectList']),
    id() {
      return this.$route.params.id * 1
    },
    nextProject() {
      if (this.curIndex === this.projectList.length - 1) return ''
      return this.getProjectInfo(this.curIndex + 1)
    },
    prevProject() {
      if (this.curIndex === 0) return ''
      return this.getProjectInfo(this.curIndex - 1)
    },
    currProject() {
      if (this.curIndex === -1) return null
      return this.getProjectInfo(this.curIndex)
    },
    curIndex () {
      return this.projectList.findIndex(o=>o.id===this.id)
    },
    contentWidth() {
      return 100 * this.allLen + '%'
    },
    imgWidth() {
      return 100 / this.allLen + '%'
    },
    moveLeft() {
      let num = (100 / this.allLen) * this.cIndex
      const max = (100 / this.allLen) * (this.allLen - 1)
      num = Math.max(0, num)
      num = Math.min(max, num)
      return num + '%'
    }
  },
  watch: {
    '$route.path'() {
      this.$forceUpdate()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initWOW()
    })
    this.$router.beforeEach((to, from, next) => {
      this.initWOW()
      next()
    })
    // this.player = this.$refs.player
    window.addEventListener('resize', this.resizePlayer)
    this.resizePlayer()
  },
  data() {
    return {
      player: null,
      cIndex: 0,
      allLen: 8,
      local: 'center',
      left: 0,
      top: 0
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizePlayer)
  },
  methods: {
    onLoadReady() {
      this.resizePlayer()
    },
    resizePlayer() {
      if (this.$refs.player) {
        const container = this.$refs.player.$el
        const containerWidth = container.offsetWidth
        const aspectRatio = 16 / 9
        const calculatedHeight = containerWidth / aspectRatio
        container.style.height = calculatedHeight + 'px'
        // this.$refs.video.$el.style.height = calculatedHeight + 'px'
        this.$refs.player.player.setSize(containerWidth, calculatedHeight)
      }
    },
    clickIcon() {
      if (this.local === 'left' && this.cIndex > 0) {
        this.cIndex -= 1
      } else if (this.local === 'right' && this.cIndex < this.allLen - 1) {
        this.cIndex += 1
      }
    },
    mousemove(e) {
      const imgBox = this.$refs.imgbox
      this.left =
        Math.min(Math.max(0, e.offsetX - 40), imgBox.clientWidth - 80) + 'px'
      this.top =
        Math.min(Math.max(0, e.offsetY - 40), imgBox.clientHeight - 80) + 'px'
      if (e.offsetX >= 0 && e.offsetX <= 200) {
        this.local = 'left'
      } else if (e.offsetX >= imgBox.clientWidth - 200) {
        this.local = 'right'
      } else {
        this.local = ''
      }
    },
    getProjectInfo(idx) {
      return this.projectList[idx] || ''
    },
    goToProject(num) {
      this.$router.push(`/project/${this.id + num}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.project {
  &-box {
    min-height: 100%;
    background: url('../assets/images/main_bg.png') no-repeat center;
    background-size: cover;
  }
  &-page {
    padding: 50px 140px 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-imgbox {
    overflow: hidden;
    height: 650px;
    position: relative;
    &-content {
      display: flex;
      align-items: center;
      transition: transform 0.3s;
    }
  }
  &-info {
    padding: 47px 140px 28px;
    &-title {
      color: $color-nav-hover;
      font-size: 30px;
    }
    &-desc {
      color: $base-color;
      font-size: 24px;
      margin-top: 10px;
    }
  }
  &-loop {
    padding: 0 140px;
  }
  &-img {
    display: block;
    pointer-events: none;
  }
}

.page {
  &-item {
    cursor: pointer;
    font-size: 36px;
    color: $base-color;
    visibility: hidden;
    &.show {
      visibility: unset;
    }
  }
  &-prev {
    &::before {
      content: '<';
    }
  }
  &-next {
    &::after {
      content: '>';
      display: inline-block;
    }
  }
}
.video {
  width: 100%;
  position: relative;
  > iframe {
    width: 100%;
    height: 100%;
  }
}

.icon {
  position: absolute;
  width: 80px;
  height: 80px;
  // border: 1px solid red;
  z-index: 1;
  pointer-events: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  &.left {
    background-image: url('../assets/images/left.png');
  }
  &.right {
    background-image: url('../assets/images/right.png');
  }
}
.imgMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .project {
    &-loop,
    &-page,
    &-info {
      padding:30px 20px;
    }
    &-info {
      &-desc {
        font-size: $font-size-mobile-base;
      }
      &-title {
        font-size: $font-size-mobile-title;
      }
    }
  }
  .page {
    &-item {font-size: $font-size-mobile-base;}
  }
}

</style>
