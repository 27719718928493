<template>
  <div class="project-box">
    <div class="project-info">
      <!-- <div class="project-info-title wow bounceInLeft">Game Development</div> -->
      <div class="project-info-title wow bounceInLeft">
        Full Game Development and Testing Services
      </div>
      <div class="project-info-desc wow bounceInLeft" data-wow-delay="0.15s">
        Engines:
      </div>
      <div
        class="project-info-desc wow bounceInLeft"
        v-for="(item, i) in dataList"
        :key="i"
        :data-wow-delay="`${0.15 * (i + 2)}s`"
      >
        {{ item }}
      </div>
    </div>
    <div class="project-loop">
      <div class="video" ref="video">
        <video
          src="/videos/game.mp4"
          controls
          poster="/images/game_poster.png"
          muted
        ></video>
      </div>
    </div>
    <image-box></image-box>
  </div>
</template>

<script>
import ImageBox from '../components/ImageBox.vue'
export default {
  watch: {
    '$route.path'() {
      this.$forceUpdate()
    }
  },
  components: {
    ImageBox
  },
  mounted() {
    this.$nextTick(() => {
      this.initWOW()
    })
  },
  data() {
    return {
      dataList: ['Unity 3D', 'Unreal Engine 4', 'Unreal Engine 5']
    }
  }
}
</script>

<style lang="scss" scoped>
.project {
  &-box {
    min-height: 100%;
    background: url('../assets/images/main_bg.png') no-repeat center;
    background-size: cover;
  }
  &-page {
    padding: 50px 50px 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-loop {
    margin-bottom: 20px;
  }
  &-imgbox {
    overflow: hidden;
    height: 650px;
    position: relative;
    &-content {
      display: flex;
      align-items: center;
      transition: transform 0.3s;
    }
  }
  &-info {
    padding: 47px 140px 28px;
    &-title {
      color: $color-nav-hover;
      font-size: 30px;
    }
    &-desc {
      color: $base-color;
      font-size: 24px;
      margin-top: 10px;
    }
  }
}

.video {
  margin: 0 140px;
  position: relative;
  > video {
    width: 100%;
  }
}

@media screen and (max-width: 1439px) {
  .project {
    &-info {
      padding: 24px 70px 20px;
      &-desc {
        font-size: 18px;
      }
      &-title {
        font-size: 22px;
      }
    }
    &-loop {
      padding: 0 70px;
    }
    &-page {
      padding: 20px 70px;
    }
  }

  .page-item {
    font-size: 22px;
  }
}
</style>
