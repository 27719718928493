<template>
  <div class="art-box">
    <div class="art-imgbox">
      <video
        style="width: 100%"
        v-if="id === 'game'"
        src="/videos/game.mp4"
        poster="/images/game_poster.png"
        controls
        muted
      ></video>
      <div class="swiper-container" v-else>
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(img, i) in loopImgList"
            :hello="`${id}_${img}_i`"
            :key="`${id}_${img}_${i}`"
          >
            <img class="art-headimg" :src="`/images/${img}`" />
          </div>
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <div class="art-about">
      <div class="art-about-itembox" v-for="(item, i) in infoTextList" :key="i">
        <div class="art-title" :class="{ tit: i === 0 }">{{ item.title }}</div>
        <div class="art-about-content">
          <div
            class="art-about-item"
            :class="[item.len || '']"
            v-for="(txt, i) in item.desc"
            :key="i"
          >
            {{ txt }}
          </div>
        </div>
      </div>
    </div>
    <image-box :img-list="artGroupImgList"></image-box>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import { mapGetters } from 'vuex'
import ImageBox from '../components/ImageBox.vue'
import { projectIds, projectImgs } from '../utils'

const artList = ['2d', '3d', 'vfx', 'ani', 'motion', 'game']
export default {
  beforeRouteEnter(to, from, next) {
    if (!artList.includes(to.params.id)) {
      next('/art/2d')
    } else {
      next()
    }
  },
  watch: {
    '$route.path'() {
      this.updateSwiper()
    }
  },
  components: {
    ImageBox
  },
  computed: {
    ...mapGetters(['projectList', 'artImgList']),
    id() {
      return this.$route.params.id || 'hello'
    },
    infoTextList() {
      return this.infoText[this.id] || []
    },
    loopImgList() {
      return this.loopImg[this.id] || []
    },
    artGroupImgList() {
      const obj = {
        '2d': [
          { img: projectImgs.art_league_1, id: projectIds.league_of_legends },
          { img: projectImgs.art_apex_1, id: projectIds.apex },
          { img: projectImgs.art_dragon_1 },
          { img: projectImgs.art_honor_1, id: projectIds.honor_kings },
          { img: projectImgs.art_team_1 },
          { img: projectImgs.art_gen_1, id: projectIds.genshin }
        ],
        '3d': [
          { img: projectImgs.art_apex_1, id: projectIds.apex },
          { img: projectImgs.art_league_1, id: projectIds.league_of_legends },
          { img: projectImgs.art_dragon_1 },
          { img: projectImgs.art_tom_1, id: projectIds.tom_clancy },
          { img: projectImgs.art_call_duty_1, id: projectIds.call_of_duty_m },
          { img: projectImgs.art_knives_1 }
        ],
        vfx: [
          { img: projectImgs.art_apex_1, id: projectIds.apex },
          { img: projectImgs.art_league_1, id: projectIds.league_of_legends },
          { img: projectImgs.art_honor_1, id: projectIds.honor_kings },
          { img: projectImgs.art_tom_1, id: projectIds.tom_clancy },
          { img: projectImgs.art_call_duty_1, id: projectIds.call_of_duty_m },
          { img: projectImgs.art_gen_1, id: projectIds.art_gen_1 }
        ],
        ani: [
          { img: projectImgs.art_apex_1, id: projectIds.apex },
          { img: projectImgs.art_league_1, id: projectIds.league_of_legends },
          { img: projectImgs.art_call_duty_1, id: projectIds.call_of_duty_m },
          { img: projectImgs.art_tom_1, id: projectIds.tom_clancy },
          { img: projectImgs.art_honor_1, id: projectIds.honor_kings },
          { img: projectImgs.art_gen_1, id: projectIds.genshin }
        ],
        motion: [
          { img: projectImgs.art_apex_1, id: projectIds.apex },
          { img: projectImgs.art_league_1, id: projectIds.league_of_legends },
          { img: projectImgs.art_honor_1, id: projectIds.honor_kings },
          { img: projectImgs.art_tom_1, id: projectIds.tom_clancy },
          { img: projectImgs.art_call_duty_1, id: projectIds.call_of_duty_m },
          { img: projectImgs.art_gen_1, id: projectIds.art_gen_1 }
        ]
      }
      return obj[this.id] || this.artImgList
    },
    title() {
      const obj = {
        '2d': '2D Art',
        '3d': '3D Art',
        vfx: 'VFX',
        ani: 'Animation',
        motion: 'Motion Capture Studio'
      }
      return obj[this.id] || ''
    },
    bottomImgList() {
      let arr = []
      for (let i = 1; i <= 6; i++) {
        arr.push(`/images/${this.id}_${i}.png`)
      }
      return arr
    }
  },

  mounted() {
    this.initSwiper()
    this.$router.beforeEach((to, from, next) => {
      this.initWOW()
      next()
    })
    this.$router.afterEach(() => {
      this.$forceUpdate()
    })
    this.$nextTick(() => {
      this.initWOW()
    })
  },
  methods: {
    destroySwiper() {
      try {
        this.swiper.destroy(true, false)
      } catch (error) {
        //
      }
    },
    updateSwiper() {
      this.destroySwiper()
      this.$nextTick(() => {
        this.initSwiper()
      })
    },
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: true
      })
    }
  },
  data() {
    return {
      swiper: null,
      animationDom: null,
      loopImg: {
        '2d': [
          '2d_loop1.jpg',
          '2d_loop2.jpg',
          '2d_loop3.jpg',
          '2d_loop4.jpg'
          // '2d_loop5.jpg'
        ],
        '3d': [
          '3d_loop1.webp',
          '3d_loop2.webp',
          '3d_loop3.webp',
          '3d_loop4.webp',
          '3d_loop5.webp',
          '3d_loop6.webp',
          '3d_loop7.webp',
          '3d_loop8.webp',
          '3d_loop9.webp'
        ],
        ani: [
          // 'ani_loop1.jpg',
          // 'ani_loop2.jpg',
          'ani_loop3.jpg',
          'ani_loop4.jpg'
        ],
        vfx: ['vfx_loop2.bmp', 'vfx_loop3.jpg'],
        motion: ['motion_loop1.jpg', 'motion_loop2.jpg', 'motion_loop3.jpg']
      },

      infoText: {
        '2d': [
          {
            title: '2D Art',
            desc: [
              'Art direction services',
              '2D concept art',
              '2D illustrations',
              '2D characters and objects',
              '2D environments'
            ]
          }
        ],
        '3d': [
          {
            title: '3D Art',
            desc: [
              '3D characters and creatures',
              '3D vehicles and objects',
              '3D environment',
              'Level design'
            ]
          }
        ],
        ani: [
          {
            title: 'Animation',
            desc: [
              '2D Animation (Spine Animation, Flash Animation)',
              '3D Animation',
              'Skinning and Rigging',
              'In-game cut-scene',
              'Pre-rendered cinematics (CG animation)',
              'Promotional user-growth video',
              'Production'
            ]
          },
          {
            title: '',
            len: 'all',
            desc: [
              '(We will send animation reel or provide online review per request.)'
            ]
          }
        ],
        vfx: [
          { title: 'VFX', desc: ['Visual Effects Design and Creation'] },
          {
            title: 'Engines:',
            len: 'auto',
            desc: ['Unity', 'Unreal Engine 4', 'Unreal Engine 5', 'AE', 'Cocos']
          },
          {
            title: '',
            len: 'all',
            desc: [
              '(We will send VFX reel or provide online review per request.)'
            ]
          }
        ],
        motion: [
          {
            title: 'Motion Capture Studio',
            desc: [
              'Digital humans',
              'Digital doubles',
              'Facial capture',
              'Motion capture',
              'Performance capture',
              'Mocap cleanup',
              '3D animation (face and body)',
              'Action capture refinement',
              'Rigging'
            ]
          },
          {
            title: '',
            len: 'all',
            desc: ['(We will send motion capture reel per request.)']
          }
        ],
        game: [
          { title: 'Full Game Development and Testing Services', desc: [] },
          {
            title: 'Engines:',
            desc: [
              'Unity 3D',
              'Unreal Engine 4',
              'Unreal Engine 5',
              'Cocos Creator'
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.art {
  &-box {
    min-height: 100%;
    background: url('../assets/images/main_bg.png') no-repeat center;
    background-size: cover;
    padding-bottom: 60px;
  }
  &-imgbox {
    padding-top: 62px;
    margin: 0 205px 28px;
    overflow: hidden;
  }
  &-headimg {
    display: block;
    width: 100%;
  }
  &-about {
    padding: 0 25px;
    font-size: 36px;
    color: $base-color;
    &-content {
      margin-bottom: 20px;
    }
    &-itembox {
      margin: 26px 200px 10px;
    }
    &-item {
      &.auto {
        margin-right: 30px;
        width: unset;
      }
      font-size: 34px;
      line-height: 42px;
    }
  }
  &-title {
    &.tit {
      color: $color-nav-hover;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .art {
    &-imgbox {
      margin: 0 100px 20px;
    }
    &-about {
      &-item {
        font-size: 20px;
        line-height: 32px;
      }
      &-itembox {
        margin: 26px 100px 10px;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .art {
    &-imgbox {
      margin: 0 100px 20px;
    }
    &-about {
      &-item {
        font-size: 16px;
        line-height: 24px;
      }
      &-itembox {
        margin: 26px 100px 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  $mg20: 20px;
  .art {
    &-title {
      font-size: 26px;
    }
    &-imgbox {
      margin: 0 $mg20 $mg20;
    }
    &-about {
      &-item {
        font-size: 18px;
        line-height: 24px;
      }
      &-itembox {
        margin: 26px 0 10px;
      }
    }
  }
}
</style>

<style>
.swiper-wrapper {
  align-items: center;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.swiper-container {
  --swiper-theme-color: #fff;
  --swiper-pagination-color: #fff;
}
</style>
